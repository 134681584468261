/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

if(typeof wpml_cookies == 'undefined') {
    wpml_cookies = [];
}

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Raptus = {
        find: function(container, selector) {
            var result = container.find(selector),
                parts = selector.split(',');
            $.each(parts, function(i, sel) {
                var parts = $.trim(sel).split(' '),
                        res = container.filter(parts.shift());
                if(!res.length) {
                    return;
                }
                if(parts.length) {
                    res = res.find(parts.join(' '));
                }
                result = result.add(res);
            });
            return result;
        },
        // All pages
        'common': {
            init: function(container) {
                // JavaScript to be fired on all pages

                var shop_bar = $('.header__shop-bar'),
                    meta_bar = $('.header__meta-bar'),
                    cat_bar = $('.category-bar .header__category-bar'),
                    header = $('.header__shop-bar, .header__meta-bar'),
                    header_container = $('.header');

                // lazy load sidepanels
                Raptus.find(container, '[data-sidepanel]:not(.active)').each(function() {
                    var elm = $(this),
                        name = elm.data('sidepanel'),
                        panel = $('<div class="collapse sidepanel-menu sidepanel-right loading" id="sidepanel-' + name + '">' +
                                      '<button class="close pull-right" type="button" aria-expanded="false" aria-controls="sidepanel-' + name + '" aria-label="Close">' +
                                          '<span aria-hidden="true">&times;</span>' +
                                      '</button>' +
                                  '</div>');
                    panel.insertAfter(elm);
                    elm
                    .attr('data-target', '#sidepanel-' + name)
                    .attr('data-toggle', 'active')
                    .attr('data-body-toggle', 'sidepanel-active sidepanel-' + name + '-active')
                    .one('click', function(e) {
                        e.preventDefault();
                        mdmtl.ajax.get(
                            {action: 'raptus_sidepanel_' + name,
                             referrer: document.location.href},
                            function(data) {
                                panel.signalRemove().html('').append($($.trim(data)).find('> *'));
                                panel.initialize().removeClass('loading');
                                elm.trigger('df.toggler.add');
                            }
                        );
                    });
                });

                Raptus.find(container, '#sidepanel-search').each(function() {
                    var panel = $(this),
                        searchform = $('.searchbox-wrapper .search-form');
                    if(searchform.length) {
                        panel.css('left', searchform.offset().left);
                        $(window).smartresize(function() {
                            panel.css('left', searchform.offset().left);
                        });
                    }
                });

                Raptus.find(container, 'div.quantity:not(.buttons_added), td.quantity:not(.buttons_added)')
                    .addClass('buttons_added')
                    .append('<input type="button" value="+" class="plus" tabindex="-1" />')
                    .prepend('<input type="button" value="-" class="minus" tabindex="-1" />');

                Raptus.find(container, '[data-toggle]:not([data-target^="#sidepanel"]):not(data-initialized):not([data-toggle="popover"])').each(function(i,elm) {
                    new Toggler($(elm), {});
                }).data('initialized', 1);

                Raptus.find(container, '[data-toggle][data-target^="#sidepanel"]:not(data-initialized)').each(function(i,elm) {
                    var $elm = $(elm);
                    new Toggler($elm, {
                        companionsSelector: '[data-toggle][data-target^="#sidepanel"].active:not([data-target="' + $elm.data('target') + '"])',
                        closeSelector: '> .close'
                    });
                }).data('initialized', 1);

                Raptus.find(container, '.header').each(function(i,elm) {
                    var header = $(elm),
                        fixed = header.add(header.find('.menu-bottom'));
                    if(header.is('.header-sticky')) {
                        new Sticky(header,{
                            viewportEdge: 'top',
                            elmEdge: 'bottom',
                            offsetTop: meta_bar.length ? {elm: meta_bar, prop: 'height', offset: header.data('offset')} : header.data('offset')
                        });
                        var scroll_bar_width = Custom.scrollBarWidth();
                        if(scroll_bar_width) {
                            fixed.css('right', scroll_bar_width);
                        } else {
                            $('#wrapper').one('scroll', function(e) {
                                $(window).trigger('resize');
                            });
                        }
                        $(window).smartresize(function() {
                            scroll_bar_width = Custom.scrollBarWidth();
                            if(scroll_bar_width) {
                                fixed.css('right', scroll_bar_width);
                            } else {
                                fixed.css('right', '');
                            }
                        });
                    }
                });

                Raptus.find(container, '.sidebar-sticky').each(function(i,elm) {
                    var scrollTop = $('#wrapper').scrollTop();
                    new Sticky($(elm),{
                        offsetTop: function() { return $(elm).offset().top + scrollTop - $('header').height() - 30 },
                        $pushingElm: $('footer'),
                        fixedPositioning: true,
                        clone: true
                    });
                });

                Raptus.find(container, '.sidepanel-content:not(data-initialized)').data('initialized', 1);

                if(meta_bar.length) {
                    Raptus.find(container, '.header.banner .logo.animate').each(function(i,elm) {
                        new ScrollAni($(elm),{
                            startStyles: {
                                width: {elm: header, prop: 'height'},
                                height: {elm: header, prop: 'height'},
                                fontSize: {elm: header, prop: 'height', factor: 0.6, unit: 'px'},
                                lineHeight: {elm: header, prop: 'height', factor: 1.035, unit: 'px'},
                                marginTop: {elm: meta_bar, prop: 'height', factor: -1, unit: 'px'}},
                            endStyles: {
                                width: {elm: shop_bar, prop: 'height'},
                                height: {elm: shop_bar, prop: 'height'},
                                fontSize: {elm: shop_bar, prop: 'height', factor: 0.6, unit: 'px'},
                                lineHeight: {elm: shop_bar, prop: 'height', factor: 1.035, unit: 'px'},
                                marginTop: 0},
                            startPos: 0,
                            endPos: {elm: meta_bar, prop: 'height'},
                            viewportEdge: 'top'
                        });
                    });

                    Raptus.find(container, '.sidepanel-menu:not(data-initialized)').each(function(i,elm) {
                        new ScrollAni($(elm),{
                            startStyles: {
                                top: {elm: header, prop: 'height'},
                                paddingBottom: {elm: meta_bar, prop: 'height'}},
                            endStyles: {
                                top: {elm: shop_bar, prop: 'height'},
                                paddingBottom: 0},
                            startPos: 0,
                            endPos: {elm: meta_bar, prop: 'height'},
                            viewportEdge: 'top'
                        });
                    }).data('initialized', 1);
                }

                Raptus.find(container, '.scroll-to-top').off('click').on('click',function(e) {
                    e.preventDefault();
                    $('#wrapper').animate({ scrollTop: 0 },500);
                });

                Raptus.find(container, 'a[href^="#"]:not([href="#"])').each(function() {
                    var elm = $(this),
                        target = $(elm.attr('href') + ',a[name="' + elm.attr('href').substring(1) + '"]');
                    if(!target.length || elm.data('events')) {
                        return;
                    }
                    elm.on('click',function(e) {
                        e.preventDefault();
                        $('body, html, #wrapper').animate({scrollTop: target.offset().top + $('#wrapper').scrollTop() - $('#wrapper').offset().top - (header_container.is('.header.banner') ? header_container.height() : 0)}, 500, function() {
                            if(target.attr('href')) {
                                target.focus();
                            }

                            if (window.location.href.indexOf(elm.attr('href')) < 0) {
                                history.pushState(null, '', elm.attr('href'));
                            }
                        });
                    });
                });

                Raptus.find(container, '.nf-form-cont').each(function() {
                    var container = $(this);
                    function bind() {
                        var button = container.find('.submit-wrap input[type="button"]');
                        if(!button.length)
                            return window.setTimeout(bind, 100);
                        button.on('click', function(e) {
                            var top = container.offset().top + $('#wrapper').scrollTop() - $('#wrapper').offset().top - (header_container.is('.header.banner') ? header_container.height() : 0) - 30;
                            if(top < $('#wrapper').scrollTop())
                                $('body, html, #wrapper').animate({scrollTop: top}, 500);
                        });
                    }
                    bind();
                });

                Raptus.find(container, '.hero:has(.toggle-trigger)').each(function(i,elm) {
                    var hero = $(elm),
                        timer = false,
                        interval = hero.data('interval') || 7000;

                    function next() {
                        requestAnimationFrame(function() {
                            var next = hero.find('.toggle-trigger:checked + .toggle-target + .toggle-trigger');
                            if(!next.length) {
                                next = hero.find('.toggle-trigger:first');
                            }
                            hero.find('.toggle-trigger[id="' + next.attr('id') + '"]').prop('checked', 'checked').trigger('change');
                        });
                    }

                    hero.find('.toggle-trigger').change(function(e) {
                        timer.restart();
                    });

                    if (hero.hasClass('hero-stoppable'))
                        hero.on('mouseenter', function() {
                            hero.addClass('stop');
                            timer.pause();
                        }).on('mouseleave', function() {
                            hero.removeClass('stop');
                            timer.resume();
                        });

                    timer = new window.timer(next, interval);

                    hero.swipe({
                        swipe: function(e, direction, distance, duration, fingerCount, fingerData) {
                            if(direction != 'left' && direction != 'right')
                                return;
                            next();
                            timer.pause();
                            timer = new window.timer(next, 7000);
                        },
                        allowPageScroll: 'vertical'
                    });
                });

                Raptus.find(container, '.express-order-form').each(function() {
                    var form = $(this),
                        button = form.find('button'),
                        timer = false;
                    form
                        .ExpressOrder()
                        .on('expressorder.before.results', function() {
                            form.next('search-results').signalRemove();
                        })
                        .on('expressorder.after.results', function() {
                            form.next('search-results').initialize();
                        })
                        .on('expressorder.add', function() {
                            if(timer) {
                                window.clearTimeout(timer);
                            }
                            button
                                .removeClass('success')
                                .removeClass('failure');
                        })
                        .on('expressorder.added.success', function() {
                            button.addClass('success');
                            timer = window.setTimeout(function() {
                                button.removeClass('success');
                            }, 2000);
                        })
                        .on('expressorder.added.failed', function() {
                            button.addClass('failure');
                            timer = window.setTimeout(function() {
                                button.removeClass('failure');
                            }, 2000);
                        });
                });

                Raptus.find(container, '.buy form.cart:has(.single_add_to_cart_button)').each(function() {
                    var form = $(this),
                        button = form.find('.single_add_to_cart_button'),
                        timer = false;
                    form.off('submit').on('submit', function(e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        if(timer) {
                            window.clearTimeout(timer);
                        }
                        button
                            .removeClass('success')
                            .removeClass('failure')
                            .addClass('loading');
                        $.post(woocommerce_params.wc_ajax_url.replace('%%endpoint%%', 'add_to_cart'),
                               {product_id: form.find('input[name="add-to-cart"]').val(),
                                quantity: form.find('input[name="quantity"]').val()})
                        .done(function() {
                            $('body').trigger('added_to_cart');
                            button.addClass('success');
                            timer = window.setTimeout(function() {
                                button.removeClass('success');
                            }, 2000);
                        })
                        .fail(function() {
                            button.addClass('failure');
                            timer = window.setTimeout(function() {
                                button.removeClass('failure');
                            }, 2000);
                        })
                        .always(function() {
                            button.removeClass('loading');
                        });
                    });
                });

                Raptus.find(container, '.wl-table .product-purchase .button').each(function() {
                    var button = $(this),
                        timer = false;
                    button.off('click').on('click', function(e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        button
                            .removeClass('success')
                            .removeClass('failure')
                            .addClass('loading');

                        $.get(button.attr('href'))
                        .done(function(data) {
                            var response = $('<div />').append(data);
                            $('body').trigger('added_to_cart');
                            button.addClass('success');
                            timer = window.setTimeout(function() {
                                button.removeClass('success');
                            }, 2000);
                            var notices = response.find('.notices');
                            if(notices) {
                                mdmtl.woocommerce.addNotices(notices.find('> *'));
                            }
                        })
                        .fail(function() {
                            button.addClass('failure');
                            timer = window.setTimeout(function() {
                                button.removeClass('failure');
                            }, 2000);
                        })
                        .always(function() {
                            button.removeClass('loading');
                        });
                    });
                });

            },
            finalize: function(container) {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function(container) {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // single product
        'single_product': {
            init: function(container) {

                var header = $('.header.banner'),
                    cat = $('.category-bar .header__category-bar');

                if($('body').hasClass('sticky-cart')) {
                    Raptus.find(container, '.entry-summary').each(function(i,e) {
                        var scroll_bar_width = Custom.scrollBarWidth(),
                        elm = $(e);
                        if(scroll_bar_width) {
                            elm.css('right', scroll_bar_width);
                        }
                        $(window).smartresize(function() {
                            scroll_bar_width = Custom.scrollBarWidth();
                            if(scroll_bar_width) {
                                elm.css('right', scroll_bar_width);
                            } else {
                                elm.css('right', '');
                            }
                        });

                        function init() {
                            new Sticky(elm,{
                                $elm: elm,
                                $offsetElm: elm.closest('.row'),
                                $viewportOffsetElm: header.hasClass('header-sticky').length ? (cat.length ? cat : $('.header.header-sticky')) : false,
                                        viewportEdge: 'top',
                                        elmEdge: 'bottom',
                                        clone: true
                            });
                            $('<a class="icon-to-top scroll-to-top" href="#"></a>')
                            .hide()
                            .appendTo(elm)
                            .initialize();
                            elm.wrapInner('<div class="wrapper-inner" />');
                        }

                        if(elm.find('.variations_form').length && typeof mdmtl.single_product_attribute_selector !== 'undefined') {
                            $(document).on('wc_variation_form', init);
                        } else {
                            init();
                        }
                    });
                }

                Raptus.find(container, '.total-amount').each(function() {
                    var context = $(this),
                        regular = context.find('.regular'),
                        discount = context.find('.discount'),
                        discount_incl = context.find('.discount-incl'),
                        price = context.find('.yourprice');
                    context
                    .off('mdmtl.total.amount.update')
                    .on('mdmtl.total.amount.update', function(e) {
                        e.preventDefault();
                        if(!e.price) {
                            return;
                        }
                        var data = $.parseJSON(e.price);
                        if(!data) {
                            return;
                        }
                        if(data.discount) {
                            if(!regular.length) {
                                regular = $('<div class="price-info__block regular"><span class="desc">' + data.regular_label + '</span><span class="value">' + data.regular + '</span></div>').insertBefore(price);
                            } else {
                                regular.find('.value').html(data.regular);
                            }
                            if(!discount.length) {
                                discount = $('<div class="price-info__block discount"><span class="desc">' + data.discount_label + '</span><span class="value">' + data.discount + '%</span></div>').insertBefore(regular);
                            } else {
                                discount.find('.value').html(data.discount + '%');
                            }
                            if(!discount_incl.length) {
                                discount_incl = $('<span class="discount-incl">' + data.discount_incl_label.replace('%s', '<span>' + data.discount + '%</span>') + '</span>').appendTo(price.find('.desc'));
                            } else {
                                discount_incl.find('span').html(data.discount + '%');
                            }
                        } else {
                            regular.remove();
                            regular = context.find('.regular');
                            discount.remove();
                            discount = context.find('.discount');
                            discount_incl.remove();
                            discount_incl = context.find('.discount-incl');
                        }
                        price.find('.value').html(data.price);
                        price.find('.desc .amount').text(data.quantity);
                    })
                    .off('mdmtl.total.amount.data')
                    .on('mdmtl.total.amount.data', function(e) {
                        e.ajax_data.action = 'raptus_price_extended';
                    });
                });
            }
        },
        // archive
        'archive': {
            init: function(container) {

                Raptus.find(container, '.filter-bar').each(function() {
                    new FilterBar($(this), $(this).data());
                });

                if(container.is('body')) {
                    container.on('raf.loaded', function(e, new_container) {
                        var toggle = $(e.target).find('#product-filter-toggle');
                        if(toggle.is(':checked')) {
                            new_container.find('#product-filter-toggle').attr('checked', 'checked');
                        }
                    });

                    // Scroll to top of page when filtering, sorting or page zapping
                    container.on('raf.load', function(e) {
                        $('#wrapper').animate({scrollTop : Math.min($('#wrapper').scrollTop(), $('#products').position().top - $('header > *:last').height() - $('header > *:last').position().top)}, 500);
                    });
                }

                Raptus.find(container, '[data-change-view]').each(function() {
                    var both = $(this).data('change-view-both');

                    var target = $('#products');

                    var view = $.readCookie('product-view');
                    if (view === 'list' && !target.hasClass('list')) {
                        target.addClass('list');
                    }
                    if (view === 'grid' && target.hasClass('list')) {
                        target.removeClass('list');
                    }

                    if (! both) {
                        var button = $('<a href="javascript://" id="products-change-view" class="button mini icon-list-3" data-toggle="list" data-target="#products" title="' + $(this).data('change-view') + '"></a>')
                        .prependTo($(this))
                        .initialize()
                        .click(function(e) {
                            $.createCookie('product-view', target.hasClass('list') ? 'list' : 'grid', 356, '/');
                            $(this).text($(this).parent('[data-change-view]').data('change-view-' + (!target.hasClass('list') ? 'list' : 'grid')));
                        });


                        button
                        .clone(false)
                        .prependTo($('.product-toolbar-paginate-bottom'))
                        .initialize();

                        button.text($(this).data('change-view-' + (!target.hasClass('list') ? 'list' : 'grid')));

                    } else {
                        var button_grid = $('<a href="javascript://" id="products-change-view" class="button mini icon-grid" data-toggle="list" data-target="#products" data-oneway="remove" title="' + $(this).data('change-view') + '"></a>')
                        .prependTo($(this))
                        .initialize()
                        .click(function(e) {
                            $.createCookie('product-view', 'grid', 356, '/');
                            $(this).text($(this).parent('[data-change-view]').data('change-view-grid'));
                        });

                        var button_list = $('<a href="javascript://" id="products-change-view" class="button mini icon-list-3" data-toggle="list" data-target="#products" data-oneway="add" title="' + $(this).data('change-view') + '"></a>')
                        .prependTo($(this))
                        .initialize()
                        .click(function(e) {
                            $.createCookie('product-view', 'list', 356, '/');
                            $(this).text($(this).parent('[data-change-view]').data('change-view-list'));
                        });


                        button_grid
                        .clone(false)
                        .prependTo($('.product-toolbar-paginate-bottom'))
                        .initialize();
                        button_list
                        .clone(false)
                        .prependTo($('.product-toolbar-paginate-bottom'))
                        .initialize();

                        button_grid.text($(this).data('change-view-grid'));
                        button_list.text($(this).data('change-view-list'));
                    }


                });
            }
        },
        // checkout
        'woocommerce_checkout': {
            init: function(container) {
                Raptus.find(container, '#order_review').each(function(i,elm) {
                    var $elm = $(elm),
                        $heading = $elm.prev('#order_review_heading'),
                        container = $elm.closest('.woocommerce'),
                        push = $('form.checkout ~ div, footer').first(),
                        header = $('.contact-header .header__contact-bar, body:not(.contact-header) .header'),
                        reset = $($elm.get(0)),
                        offset = function() {
                            var o = header.height() + 20;
                            if($heading.length)
                                o += $elm.offset().top - $heading.offset().top;
                            return o;
                        },
                        pushingOffset = function() {
                            return push.offset().top - (container.offset().top + container.outerHeight());
                        },
                        doReset = function(t) {
                            t.status = [];
                            t.css = [];
                            reset.each(function() {
                                t.status.push($(this).hasClass('sticky'));
                                t.css.push($(this).attr('style'));
                                var clone = $(this).next('.sticky-clone').hide(),
                                    emptyClone = clone.html() == '';
                                if(emptyClone)
                                    clone.css({width: $(this).outerWidth(), height: $(this).outerHeight()});
                                else
                                    clone.html($(this).html());
                            })
                                .removeClass('sticky')
                                .removeAttr('style');
                        },
                        doRestore = function(t) {
                            reset.each(function() {
                                if(t.status.shift())
                                    $(this).addClass('sticky');
                                $(this).attr('style', t.css.shift());
                            });
                        };

                    var pushing = $('.coupon_form');
                    if(!pushing.length) {
                        pushing = push;
                    } else {
                        new Sticky(pushing, {
                            offsetDelta: function() {
                                return offset() - $elm.offset().top + pushing.offset().top;
                            },
                            fixedPositioning: true,
                            $pushingElm: push,
                            pushingOffset: pushingOffset,
                            reset: doReset,
                            restore: doRestore
                        });
                        reset = reset.add(pushing);
                    }
                    new Sticky($elm, {
                        offsetDelta: offset,
                        fixedPositioning: true,
                        clone: true,
                        emptyClone: true,
                        $pushingElm: pushing,
                        pushingOffset: function() {
                            if(pushing.is('.coupon_form'))
                                return parseInt(pushing.css('margin-top'));
                            return pushingOffset();
                        },
                        reset: doReset,
                        restore: doRestore
                    });
                    if($heading.length) {
                        new Sticky($heading, {
                            offsetDelta: function() {
                                return header.height() + 20;
                            },
                            fixedPositioning: true,
                            clone: true,
                            $pushingElm: $elm,
                            reset: doReset,
                            restore: doRestore
                        });
                        reset = reset.add($heading);
                    }
                    function check() {
                        if(pushing.is('.coupon_form')) {
                            pushing.data('Sticky').loop(true);
                        }
                        $elm.data('Sticky').loop(true);
                        if($heading.size()) {
                            $heading.data('Sticky').loop(true);
                        }
                        window.setTimeout(function() {
                            if(pushing.is('.coupon_form')) {
                                pushing.data('Sticky').checkSticky();
                                pushing.data('Sticky').stopLoop();
                            }
                            $elm.data('Sticky').checkSticky();
                            $elm.data('Sticky').stopLoop();
                            if($heading.length) {
                                $heading.data('Sticky').checkSticky();
                                $heading.data('Sticky').stopLoop();
                            }
                        }, 500);
                    }
                    $('.checkout-step-toggle').on('change', check);
                    $(document.body).on('updated_checkout', function() {
                        $(window).trigger('resize');
                        check();
                    });
                });

                var timer = false,
                    wrapper = $('#wrapper');
                Raptus.find(container, '.checkout-step-toggle').on('click', function(e) {
                    var step = $(this).next('.checkout-step');
                    if(timer) {
                        window.clearTimeout(timer);
                    }
                    timer = window.setTimeout(function() {
                        var scrollTop = wrapper.scrollTop(),
                            stepTop = step.position().top;
                        if(stepTop > scrollTop) {
                            return;
                        }
                        wrapper.stop().animate({scrollTop: stepTop}, 250);
                    }, 250);
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Raptus;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function(container) {

            if(typeof container === 'undefined') {
                container = $('body');
            }

            // Fire common init JS
            UTIL.fire('common', 'init', container);

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm, 'init', container);
                UTIL.fire(classnm, 'finalize', container);
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize', container);
        }
    };

    // Load Events
    $(document).ready(function(e) {
        UTIL.loadEvents();
    });

    var Custom = {

        pre_init: function(container) {
            this.attributeSelector(container);
        },

        init: function(container) {
            if(typeof container === 'undefined') {
                container = $('body');

                container.on('added_to_cart', this.updateMiniCart);
                container.on('mdmtl.cart.update', this.updateMiniCart);
                container.on('mdmtl.wishlists.added', this.updateWishlists);

                this.fullWidthRows(container);
            }

            this.replace(container);
            this.tooltip(container);
            this.popover(container);
            this.updateProductClass(container);
            this.inputs(container);
            this.search(container);
            this.loggedInAs(container);
            this.wishlist(container);
            this.sidepanelSize(container);
            this.focusedMobileNavigation(container);
        },

        /**
         * Opt-In Bootstrap Tooltip Plugin
         */
        is_touch: false,
        tooltip: function(container) {
            if(container.is('body'))
                container.one('touchstart', function(e) {
                    Custom.is_touch = true;
                    $('[data-original-title]').each(function() {
                        if($(this).is('a, label, button') || $(this).closest('a, label, button').length)
                            $(this).tooltip('destroy').off('touchstart.tooltip');
                    });
                });
            Raptus.find(container, '[data-toggle="tooltip"],[title]').each(function() {
                var elm = $(this);
                if(elm.is('img') && elm.attr('alt') === elm.attr('title') || elm.is('input.qty')) {
                    return;
                }
                if(Custom.is_touch && (elm.is('a, label, button') || elm.closest('a, label, button').length))
                    return;
                elm.on('touchstart.tooltip', function(e) {
                    Custom.is_touch = true;
                    if(!elm.is('a, label, button') && !elm.closest('a, label, button').length)
                        return;
                    elm.tooltip('destroy');
                    $('[data-original-title]').each(function() {
                        if($(this).is('a, label, button') || $(this).closest('a, label, button').length)
                            $(this).tooltip('destroy').off('touchstart.tooltip');
                    });
                });
                elm.tooltip({
                    container: elm.data('container') || 'body',
                    trigger: 'hover'
                });
            });
        },

        /**
         * Opt-In Bootstrap Popover Plugin
         */
        popover: function(container) {
            Raptus.find(container, '[data-toggle="popover"]').each(function() {
                var elm = $(this);
                elm.popover($.extend({
                    container: 'body',
                    trigger: 'hover'
                }, elm.data()));
            });
        },

        replace: function(container) {
            var combined = mdmtl.ajax.combine;
            if(!combined) {
                mdmtl.ajax.activate();
            }

            Raptus.find(container, '[data-replace]').each(function() {
                var elm = $(this);
                if(elm.hasClass('signal-loading')) {
                    elm.addClass('loading');
                }
                mdmtl.ajax.get($.extend({action: elm.data('replace')}, elm.data()), function(data) {
                    data = $($.trim(data));
                    elm.signalRemove().replaceWith(data);
                    data.initialize();
                });
            });

            if(!combined) {
                mdmtl.ajax.deactivate();
            }
        },

        updateProductClass: function(container) {
            if(!container.is('section.buy')) {
                return;
            }
            container.closest('.product').addClass('purchasable');
        },

        attributeSelector: function(container) {
            if(container.is('body')) {
                container.on('mdmtl.attribute_selector.settings', function(e) {
                    e.settings.sort = false;
                    e.settings.search = false;
                });
            }
        },

        updateMiniCart: function(e) {
            if(e) {
                e.stopImmediatePropagation();
            }
            var panel = $('#sidepanel-cart');
            if(!panel.length) {
                mdmtl.ajax.get({action: 'raptus_cart_count'}, function(data) {
                    Custom.updateCartBadge($('[data-sidepanel="cart"]'), parseInt($($.trim(data)).text()));
                });
                mdmtl.ajax.get({action: 'raptus_cart_total'}, function(data) {
                    $('[data-sidepanel="cart"] .cart-total').html(data);
                });
                return;
            }
            panel.addClass('loading');
            mdmtl.ajax.get(
                {action: 'raptus_sidepanel_cart'},
                function(data) {
                    panel.signalRemove().html('').append($($.trim(data)).find('> *'));
                    panel.initialize().removeClass('loading');
                    var trigger = panel.prev('[data-target="#sidepanel-cart"]');
                    if(!trigger.length) {
                        return;
                    }
                    Custom.updateCartBadge(trigger, parseInt(panel.find('.sidepanel-header').data('count')));
                    trigger.find('.cart-total').html(panel.find('.cart_totals .cart-subtotal > td').html());
                },
                function(data) {
                    panel.removeClass('loading');
                    // Maybe add error notice?
                }
            );
        },

        updateCartBadge: function(trigger, count) {
            var units = ['k', 'm'],
                unit = '';
            while(count > 1000 && units) {
                unit = units.shift();
                count = Math.floor(count / 100) / 10;
            }
            var badge = trigger.find('.badge');
            if(!count) {
                badge.remove();
            } else if(!badge.length) {
                trigger.append('<span class="badge">' + count + unit + '</span>');
            } else {
                badge.html(count + unit);
            }
        },

        updateWishlists: function(e) {
            var panel = $('#sidepanel-wishlists');
            if(!panel.length) {
                return;
            }
            panel.addClass('loading');
            mdmtl.ajax.get(
                {action: 'raptus_sidepanel_wishlists'},
                function(data) {
                    panel.signalRemove().html('').append($('<div />').append($.trim(data)).find('#sidepanel-wishlists > *'));
                    panel.initialize().removeClass('loading');
                    var trigger = panel.prev('[data-target="#sidepanel-wishlists"]');
                    if(!trigger.length) {
                        return;
                    }
                },
                function(data) {
                    panel.removeClass('loading');
                    // Maybe add error notice?
                }
            );
        },

        inputs: function(container) {
            container.find('input:radio, input:checkbox').each(function() {
                var $input = $(this);
                $input.on('change', function(e) {
                    $input.toggleClass('checked', $input.is(':checked'));
                });
            });
        },

        search: function(container) {
            Raptus.find(container, 'form[id="ajax-searchform"]').each(function() {
                var form = $(this),
                    panel = false,
                    results = false,
                    timer = false,
                    is_sidepanel = form.closest('.sidepanel-menu').length,
                    is_primary = form.closest('#sidepanel-primary').length;

                function displayResults(data) {
                    if(results === false) {
                        if(panel) {
                            results = panel.find('.results');
                            if(!results.length) {
                                results = false;
                            }
                        }
                    }
                    if(results === false) {
                        timer = window.setTimeout(function() {
                            displayResults(data);
                        }, 10);
                        return;
                    }
                    results.signalRemove().replaceWith(data);
                    results = panel.find('.results').initialize();
                    form.removeClass('loading');
                    panel.removeClass('loading');
                }

                form.on('raptus_ajax_search.exit', function(e) {
                    e.preventDefault();
                    if(panel) {
                        form.removeClass('loading');
                        panel.removeClass('loading');
                        if(e.esc && !is_primary || !is_sidepanel) {
                            panel.find('.close').trigger('click');
                        }
                        if(is_primary && results) {
                            results.hide();
                        }
                    }
                });

                form.on('raptus_ajax_search.same', function(e) {
                    e.preventDefault();
                    if(panel && !panel.hasClass('active') && !is_sidepanel) {
                        $('[data-target="#sidepanel-search"]').trigger('click');
                    }

                    if(form.hasClass('loading') || results === false) {
                        return;
                    }

                    var items = results.find('ul.products > li');
                    if(!items.length) {
                        return;
                    }

                    var selected = results.show().find('ul.products > li.selected');

                    // ENTER - navigate to the first found link
                    if (e.keyCode === 13 && selected.length > 0) {
                        e.originalEvent.preventDefault();
                        document.location = selected.find('a:first').attr('href');
                        return;
                    }

                    if (e.keyCode === 38 || e.keyCode === 40) {
                        selected.removeClass('selected');
                        var idx = items.index(selected);

                        // UP - select previous, or last if none or first is currently selected.
                        if(e.keyCode === 38) {
                            items.eq(idx <= 0 ? items.length - 1 : idx - 1).addClass('selected');
                        }

                        // DOWN - select next, or fist if none or last is currently selected.
                        if(e.keyCode === 40) {
                            items.eq(idx < 0 || idx === items.length - 1 ? 0 : idx + 1).addClass('selected');
                        }
                    }
                });

                form.on('raptus_ajax_search.search', function(e) {
                    if(timer) {
                        window.clearTimeout(timer);
                    }
                    if(panel === false) {
                        panel = form.closest('.sidepanel-menu');
                        if(!panel.length) {
                            panel = $('#sidepanel-search');
                        }
                        if(!panel.length) {
                            panel = false;
                        }
                    }
                    if(panel) {
                        if(!panel.hasClass('active') && !is_sidepanel) {
                            $('[data-target="#sidepanel-search"]').trigger('click');
                        }
                    }
                });

                form.on('raptus_ajax_search.dosearch', function(e) {
                    if(panel) {
                        panel.addClass('loading');
                    }
                });

                form.on('raptus_ajax_search.results', function(e) {
                    e.preventDefault();
                    displayResults(e.results);
                });

                form.on('raptus_ajax_search.init', function(e) {
                    e.preventDefault();
                });

                if(!container.is('body')) {
                    form.AjaxSearch();
                }
            });
        },

        loggedInAs : function(container) {
            if(!container.is('body')) {
                return;
            }
            container.find('a.icon-account').each(function() {
                var $elm = $(this);
                mdmtl.ajax.get(
                    {action: 'raptus_logged_in_as'},
                    function(data) {
                        $elm.attr($elm.attr('data-original-title') ? 'data-original-title' : 'title', data);
                    }
                );
            });
        },

        wishlist : function(container) {
            if(!container.is('body')) {
                return;
            }

            var timer = false;

            container.on('mdmtl.wishlists.add', '.products .wl-add-to', function(e) {
                $(this).removeClass('failure success');
                if(timer) {
                    window.clearTimeout(timer);
                }
            });

            container.on('mdmtl.wishlists.added', '.products .wl-add-to', function(e, success) {
                var button = $(this).removeClass('failure success').addClass(success ? 'success' : 'failure');
                if(timer) {
                    window.clearTimeout(timer);
                }
                window.setTimeout(function() {
                    button.removeClass('failure success');
                }, 2000);
            });
        },

        bound : false,
        nav : false,

        sidepanelSize : function(container) {
            if(!Custom.bound) {
                $(window).smartresize(Custom.sidepanelSize);
                Custom.bound = true;
                Custom.nav = $('.menu-wrapper > .menu');
            }
            var width = Custom.nav.width() - 1 + Custom.scrollBarWidth();
            $('.sidepanel-menu:not([data-width="' + width + '"])').css('width', width).attr('data-width', width);
        },

        sbwidth : false,

        scrollBarWidth : function() {
            if(typeof rtl !== 'undefined' && typeof rtl.scrollBarWidth !== 'undefined') {
                return rtl.scrollBarWidth();
            }
            if(typeof rtl !== 'undefined' && typeof rtl.js_composer !== 'undefined') {
                return rtl.js_composer.scrollBarWidth();
            }
            if($('body').height() <= $(window).height()) {
                return 0;
            }
            if(Custom.sbwidth === false) {
                var wrap = $('<div />').css({
                    position: 'absolute',
                    left: '-1000px',
                    top: '-1000px',
                    width: '100px',
                    height: '100px',
                    overflow: 'hidden'
                }).appendTo($('body'));
                var inner = $('<div />').css({
                    width: '100%',
                    height: '200%'
                }).appendTo(wrap);
                Custom.sbwidth = inner.width();
                wrap.css('overflow', 'scroll');
                Custom.sbwidth -= inner.width();
                wrap.remove();
            }
            return Custom.sbwidth;
        },

        updateFullWidthRows : function() {
            var sbwidth = Custom.scrollBarWidth();
            if(!sbwidth) {
                $('.content .vc_row-full[style]').removeAttr('style');
                return;
            }
            $('.content .vc_row-full').attr('style',
                'width: calc(100vw - ' + sbwidth + 'px) !important; ' +
                'margin-left: calc(-50vw + ' + Math.floor(sbwidth / 2) + 'px) !important; ' +
                'margin-right: calc(-50vw + ' + Math.ceil(sbwidth / 2) + 'px) !important;'
            );
        },

        fullWidthRows : function(container) {
            if(typeof rtl !== 'undefined' && typeof rtl.js_composer !== 'undefined') {
                $('body').on('mdmtl.utils.initialized mdmtl.utils.remove', rtl.js_composer.updateFullWidthRows);
                return;
            }
            Custom.updateFullWidthRows();
            $('body').on('mdmtl.utils.initialized mdmtl.utils.remove', Custom.updateFullWidthRows);
            $(window).smartresize(Custom.updateFullWidthRows);
        },

        focusedMobileNavigation : function (container) {
            $(container).on('change', 'nav input[type="radio"][id^="toggle"]', function() {
                var $this = $(this),
                    $reset = $(container).find('input[type="radio"][id^="reset"][name="'+ $this.attr('name') +'"]'),
                    $content = $this.closest('.sidepanel-content');

                var position = $content.scrollTop();
                $this.parents('.menu-item').each(function() {
                    position += $(this).position().top;
                });

                $reset.data('position', $content.scrollTop());

                $content.animate({
                    scrollTop: position
                }, 300);
            });

            $(container).on('change', 'nav input[type="radio"][id^="reset"]', function() {
                var position = $(this).data('position');

                if (! Number.isInteger(position)) return;

                $(this).closest('.sidepanel-content').animate({
                    scrollTop: position
                }, 300);
            });
        },

    };

    if(typeof rtl !== 'undefined') {
        rtl.js_composer_scrollable = rtl.scrollable = '#wrapper > div, #wrapper > footer';
    }

    var body = $('body');

    body.on('mdmtl.utils.initialized', function(e) {
        var context = $(e.target);
        UTIL.loadEvents(context);
        Custom.pre_init(context);
        Custom.init(context);
    });

    body.on('mdmtl.utils.remove', function(e) {
        var context = $(e.target);
        Raptus.find(context, '[data-original-title]').tooltip('hide');
    });

    Custom.pre_init(body);
    body.one('mdmtl.ajax.combined', function(e) {
        Custom.init();
    });

})(jQuery); // Fully reference jQuery after this point.
