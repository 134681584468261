// *******************************
// StickyInSticky
// *******************************
var StickyInSticky = function(elm, options) {
    this.init(elm, options);
};
StickyInSticky.prototype = {
    // constructor
    init: function(elm, options) {
        var t = this; t.$elm = jQuery(elm);

        t.$elm.data('StickyInSticky', t);

        t.prepareDataObject(options); // define variables
        t.bindEvents(); // bind events
    },

    // *******************************
    // VARIABLES
    // *******************************
    prepareDataObject: function (options) {
        var t = this;

        // options 
        // defaults
        t.options = {
            $elm: t.$elm,                       // element to add sticky class to
            $offsetElm: t.$elm,                 // element to check for passing scrollposition
            $pushingElm: null,                  // element that pushes the element instead of overlapping
            offsetTop: null,                    // offset to be checked for passing scrollposition
            stickyClass: 'sticky',              // class to be added
            viewportOverlapOffset: false,       // if fixed would break positioning
            viewportEdge: 'top',                // if viewport top is defined as scroll position; alternative is 'bottom'
            elmEdge: 'top',                     // if element top edge should be defined as offset; alternative is 'bottom'
            syncOffsetTop: 0,
        };

        // extend options with custom params
        jQuery.extend(t.options, options);
        // console.log(t.options);

        t.viewportOffset = 0;
        t.stickyOffset = 0;
        t.currentY = 0;
        t.tippingPoint = 0;
        t.getViewportDependentVars();
        t.originalTop = t.options.$elm.offset().top - t.viewportOffsetTop;

        // elements
        t.$window = jQuery(window);
        t.$body = jQuery('body');
    },

    getViewportDependentVars: function() {
        var t = this;

        t.getScrollposDependentVars();
        t.stickyStatus = false;
        t.stickyOffset = 0;
        t.viewportSize = getViewportSize();
        t.overlaps = false;

        // get current (not initial) elm offset
        // if no other scrolltop is defined, take $offsetElms offset 
        if(t.originalTop + t.options.$elm.outerHeight() > t.viewportSize.height) {
            t.overlaps = true;
        }

        if(t.options.offsetTop === null) {
            t.stickyOffset = t.options.$offsetElm.offset().top;

            if(t.options.elmEdge === 'bottom') {
                t.stickyOffset += t.options.$offsetElm.outerHeight();
            }
        } else {
            t.stickyOffset = calcStyle(t.options.offsetTop);
        }


        if(t.options.viewportOverlapOffset) {
            if(t.options.$elm.outerHeight() > t.viewportSize.height) {
                t.stickyOffset += t.options.$elm.outerHeight() - t.viewportSize.height;
            }
        }

        t.stickyOffset -= calcStyle(t.options.syncOffsetTop);
        t.stickyOffset -= (t.viewportOffsetTop - t.currentY);
    },

    getScrollposDependentVars: function() {
        var t = this;

        t.viewportOffsetTop = getViewportOffset().top;

        // get current (not initial) viewport size and offset
        if(t.overlaps) {
            t.viewportOffset = t.viewportOffsetTop;
            if(t.viewportOffset > calcStyle(t.options.syncOffsetTop)) {
                if(t.options.viewportEdge === 'bottom') {
                    t.viewportOffset += t.viewportSize.height;
                }
                t.viewportOffset -= calcStyle(t.options.syncOffsetTop);
            } else {
                t.viewportOffset = 0;
            }
        }

        t.pushingElmOverlap = 0;
        if(t.options.$pushingElm) {
            var elmBottomEdge = t.originalTop - t.stickyOffset + t.options.$elm.outerHeight();
            var overlap = jQuery(t.options.$pushingElm).offset().top - elmBottomEdge;
            if (overlap < 0) {
                t.pushingElmOverlap = overlap;
            }
        }
    },

    // *******************************
    // EVENTS
    // *******************************
    bindEvents: function () {
        var t = this;

        // FIRST CHECK
        setTimeout(function() {
          t.loop(false);
        }, 100);
        
        // SCROLLING
        t.$window.on('scrollstart', function() {
          t.loop(true);
        });

        t.$window.on('scrollstop', function() {
          t.stopLoop(false);
        });

        // VIEWPORT RESIZING
        t.$window.smartresize(function() {
            t.getViewportDependentVars();
            t.loop(false);
        });
    },

    // *******************************
    // LOOP
    // *******************************
    stopLoop: function () {
        var t = this;
        cancelAnimationFrame(t.animationloop);
    },

    loop: function (again) {
        var t = this;

        // call detector function
        t.checkSticky();

        // use requestanimationframe for best performance again and again
        if(again) {
            t.animationloop = requestAnimationFrame(jQuery.proxy(function() {
                t.loop(again);
            },t));
        }
    },

    // *******************************
    // DETECTOR
    // *******************************
    checkSticky: function () {
        var t = this, stickyStatus = false, y, tip = calcStyle(t.tippingPoint);

        t.getViewportDependentVars();
        t.getScrollposDependentVars();

        // detect if scrollposition passed offset
        stickyStatus = t.viewportOffset > t.stickyOffset + tip;

        // keep stickystatus in memory
        t.stickyStatus = stickyStatus;

        if(t.overlaps) {

            var docheight = 0;
            if(typeof rtl !== 'undefined') {
                jQuery(rtl.js_composer_scrollable).each(function() {
                    docheight += jQuery(this).outerHeight();
                });
            } else {
                docheight = t.$body.outerHeight();
            }

            // calculate tipping point to have enough room to scroll to the bottom
            tip = Math.min(tip, docheight - t.originalTop - t.$elm.outerHeight() - t.pushingElmOverlap, tip);

            if(t.viewportOffsetTop > tip) {
                if(stickyStatus) {
                    t.currentY = t.stickyOffset - t.pushingElmOverlap;
                } else {
                    t.currentY = t.viewportOffset - tip;
                }
                t.options.$elm.css({y: -t.currentY});
                return;
            }
        }

        t.options.$elm.css({y: 0});

    },

    setTippingPoint: function(tippingPoint) {
        var t = this;

        t.tippingPoint = tippingPoint;
        t.checkSticky();
    }
};