// *******************************
// FilterBar
// *******************************
var FilterBar = function(elm, options) {
    this.init(elm, options);
};
FilterBar.prototype = {
    // constructor
    init: function(elm, options) {
        var t = this; t.$elm = jQuery(elm);

        t.prepareDataObject(options); // define variables

        t.$filters = t.$elm.find(t.options.filterItemSelector);
        t.$selector = false;
        t.$trigger = false;

        if(t.options.threshold && t.$filters.size() > t.options.threshold) {
            jQuery('<div class="product-filters" />').appendTo(t.$elm).append(t.$filters);
            t.$selector = jQuery('<ul class="filter-selector list-inline" />').appendTo(t.$elm);
            t.$trigger = jQuery('<a href="javascript://" class="more-filter">' + t.options.more + '</a>').prependTo(t.$elm);
        }

        t.bindEvents(); // bind events
    },

    // *******************************
    // VARIABLES
    // *******************************
    prepareDataObject: function (options) {
        var t = this;

        // options
            // defaults
            t.options = {
                filterItemSelector: '.product-filter',
                filterLabelSelector: '> label',
                more: 'more',
                threshold: 3,
                cookieName: 'filter-bar'
            };

            // extend options with custom params
            jQuery.extend(t.options, options);

        // elements
            t.$window = jQuery(window);
    },

    // *******************************
    // EVENTS
    // *******************************
    bindEvents: function () {
        var t = this;

        t.onInit();

        if(t.$selector) {
            t.$selector.find('li input').on('change', function(e) {
                e.preventDefault();
                var $filterListItem = jQuery(this),
                    $filterItem = t.$filters.eq($filterListItem.closest('li').index());

                if($filterListItem.is(':checked')) {
                    $filterItem.removeClass('hidden');
                } else {
                    $filterItem.addClass('hidden');
                }
                t.setCookie();
            });
        }

        t.$filters.each(function() {
            var $filterItem = jQuery(this),
                $filterInput = $filterItem.find('> input');
            $filterInput.on('change', function(e) {
                if(!$filterInput.is(':checked')) {
                    return;
                }
                t.$filters.find('> input:not(#' + $filterInput.attr('id') + '):checked')
                    .prop('checked', false)
                    .trigger('change');
            });
        });

        jQuery('body').on('click focus', function(e) {
            if(!jQuery(e.target).closest('.product-filter').find('input[name="product-filter"]').size()) {
                t.$filters.find('> input:checked')
                    .prop('checked', false)
                    .trigger('change');
            }
        });

        if(t.$trigger) {
            t.$trigger.on('click', function(e) {
                t.$elm.toggleClass('selector-open');
                t.$trigger.trigger('blur');
            });
        }
    },

    onInit: function() {
        var t = this,
            cookieFilters = t.options.threshold ? jQuery.readCookie(t.options.cookieName) : false;
        if(cookieFilters) {
            cookieFilters = cookieFilters.split(',');
        }

        t.$filters.each(function(i, elm) {
            var $filterItem = jQuery(elm),
                checked = 'checked="checked" ',
                $filterLabel = $filterItem.find(t.options.filterLabelSelector),
                filterName = $filterLabel.text(),
                id = $filterLabel.attr('for'),
                $selected = $filterItem.find('li.chosen');

            if(cookieFilters) {
                if (jQuery.inArray(id, cookieFilters) < 0) {
                    $filterItem.addClass('hidden');
                    checked = '';
                }
            } else if (t.options.threshold && i >= t.options.threshold) {
                $filterItem.addClass('hidden');
                checked = '';
            }

            if($selected.size()) {
                jQuery('<ul class="selected" />').appendTo($filterItem).append($selected.clone(true));
            }

            if(t.$selector) {
                $filterSelectItem = '<li><label><input ' + checked + 'type="checkbox">' + filterName + '</label></li>';
                t.$selector.append($filterSelectItem);
            }
        });
    },

    setCookie: function () {
        var t = this;

        selectedFilters = [];
        t.$filters.each(function(i, elm) {
            var $filterItem = jQuery(elm);
            if(!$filterItem.hasClass('hidden')) {
                selectedFilters.push($filterItem.find(t.options.filterLabelSelector).attr('for'));
            }
        });

        jQuery.createCookie(t.options.cookieName, selectedFilters.join(','), 365);
    }
};
