String.prototype.decodeHTML = function() {
    var a = document.createElement( 'a' ); 
    a.innerHTML = this;
    return a.textContent;
};


(function($) {

    var wrapper = $('body > #wrapper');

    if(wrapper.length) {
        var win = $(window);
        wrapper.on('scroll', function(e) {
            win.trigger('scroll', e);
        });
        if(typeof $.fn.waypoint !== 'undefined') {
            $.fn.waypoint.defaults.context = wrapper;
        } else {
            $(document).ready(function() {
                if(typeof $.fn.waypoint !== 'undefined') {
                    $.fn.waypoint.defaults.context = '#wrapper';
                }
            });
        }
    }

    window.getViewportOffset = function() {
        if(wrapper.length) {
            return {
                top: wrapper.scrollTop(),
                left: wrapper.scrollLeft()
            };
        }
        return {
            top:  window.pageYOffset || document.documentElement.scrollTop   || document.body.scrollTop,
            left: window.pageXOffset || document.documentElement.scrollLeft  || document.body.scrollLeft
        };
    };

    window.getViewportSize = function() {
        if(wrapper.length) {
            return {
                height: wrapper.outerHeight(),
                width:  wrapper.outerWidth()
            };
        }
        var t = this;
        var mode, domObject, size = { height: window.innerHeight, width: window.innerWidth };

        // if this is correct then return it. iPad has compat Mode, so will
        // go into check clientHeight/clientWidth (which has the wrong value).
        if (!size.height) {
          mode = document.compatMode;
          if (mode || !$.support.boxModel) { // IE, Gecko
            domObject = mode === 'CSS1Compat' ?
              document.documentElement : // Standards
              document.body; // Quirks
            size = {
              height: domObject.clientHeight,
              width:  domObject.clientWidth
            };
          }
        }

        return size;
    };

    window.calcStyle = function(style) {
        if(style !== Object(style)) {
            return style;
        }
        var getType = {};
        if(style && getType.toString.call(style) === '[object Function]') {
            return style();
        }
        var val = 0;
        style.elm.each(function() {
            val += $(this)[style.prop]();
        });
        if('offset' in style) {
            val += style.offset;
        }
        if('factor' in style) {
            val *= style.factor;
        }
        return val;
    };

    window.calcStyles = function(styles) {
        var calc_styles = {};

        $.each(styles, function(key,val) {
            calc_styles[key] = calcStyle(val);
        });

        return calc_styles;
    };

    window.timer = function(callback, delay) {
        var timerId, start, remaining = delay;

        this.clear = function() {
            window.clearTimeout(timerId);
            remaining = delay;
        };

        this.pause = function() {
            window.clearTimeout(timerId);
            remaining -= new Date() - start;
        };

        this.resume = function() {
            start = new Date();
            window.clearTimeout(timerId);
            timerId = window.setTimeout(callback, remaining);
        };

        this.restart = function() {
            remaining = delay;
            this.resume();
        };

        this.resume();
    };

    var origAppend = $.fn.append;

    $.fn.append = function() {
        return origAppend.apply(this, arguments).trigger("append");
    };

    $.createCookie = function(name, value, days, path) {
        var expires, date; 

        if (days) {
            date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else { expires = ""; }
        document.cookie = name+"="+value+expires+";" + (path ? 'path='+path : '');
    };

    $.readCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        var c; 

        for(var i=0;i < ca.length;i++) {
            c = ca[i];
            while (c.charAt(0)===' ') { c = c.substring(1,c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length,c.length); }
        }
        return null;
    };

    $.eraseCookie = function(name) {
        $.createCookie(name,"",-1);
    };

})(jQuery);