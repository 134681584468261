// *******************************
// ScrollAni
// *******************************
var ScrollAni = function(elm, options) {
    this.init(elm, options);
};
ScrollAni.prototype = {
    // constructor
    init: function(elm, options) {
        var t = this; t.$elm = jQuery(elm);

        t.prepareDataObject(options); // define variables
        t.bindEvents(); // bind events
    },

    // *******************************
    // VARIABLES
    // *******************************
    prepareDataObject: function (options) {
        var t = this;

        // options 
            // defaults
            t.options = {
                startStyles: {},
                endStyles: {},
                startPos: t.parseOffset(t.$elm.data('start-pos')),
                $startElm:  null,
                $startElmEdge: 'top',
                endPos: t.parseOffset(t.$elm.data('end-pos')),
                $endElm: null,
                $endElmEdge: 'top',
                viewportEdge: 'top'
            };

            if (t.$elm.data('start-style')) {
                t.startStylesData = t.$elm.data('start-style').split(';');
                t.options.startStyles = {};

                jQuery.each(t.startStylesData, function(key,val) {
                    var style = val.split(':');
                    t.options.startStyles[style[0]] = style[1];
                });
            }

            if (t.$elm.data('end-style')) {
                // Styles to animate
                t.endStylesData = t.$elm.data('end-style').split(';');
                t.options.endStyles = {};

                jQuery.each(t.endStylesData, function(key,val) {
                    var style = val.split(':');
                    t.options.endStyles[style[0]] = style[1];
                });
            }

            // extend options with custom params
            jQuery.extend(t.options, options);
            // console.log(t.options);

        // vars
            t.aniPos = -1;
            t.startPos = t.options.startPos;
            t.endPos = t.options.endPos;
            t.isEndPos = false;

        // elements
            t.$window = jQuery(window);

            t.getViewportDependentVars();
    },

    getViewportDependentVars: function() {
        var t = this;

        // general vars
        t.windowHeight = t.$window.height();

        // Offsets
        if(t.options.$startElm && t.options.$startElm.length > 0) { 
            t.startPos = t.options.$startElm.offset().top;
            if(t.$startElmEdge === 'top') {
                t.startPos = t.startPos + t.$startElm.outerHeight();
            }
        } 

        if(t.options.$endElm && t.options.$endElm.length > 0) { 
            t.endPos = t.options.$endElm.offset().top;
            if(t.$endElm === 'top') {
                t.endPos = t.endPos + t.$endElm.outerHeight();
            }
        }

        t.aniDistance = calcStyle(t.endPos) - calcStyle(t.startPos);
    },

    getScrollposDependentVars: function() {
        var t = this;

        // get current (not initial) viewport size and offset
            t.viewportOffset = getViewportOffset().top;
            if(t.options.viewportEdge === 'bottom') {
                t.viewportOffset = t.viewportOffset + t.viewportSize.height;
            }

        // console.log(t.aniProgress, t.aniDistance);
        t.aniProgress = t.viewportOffset - calcStyle(t.options.startPos);
        t.aniPos = t.aniDistance === 0 ? 0 : Math.round(t.aniProgress / t.aniDistance * 100) / 100;
        if (t.aniPos < 0) { t.aniPos = 0; }
        if (t.aniPos > 1) { t.aniPos = 1; }
    },

    // *******************************
    // EVENTS
    // *******************************
    bindEvents: function () {
        var t = this;

        // FIRST CHECK
            setTimeout(function() {
              t.loop(false);
            }, 100);

        // SCROLLING
            t.$window.on('scrollstart', function() {
              t.loop(true);
            });

            t.$window.on('scrollstop', function() {
              t.stopLoop(false);
            });

        // VIEWPORT RESIZING
            t.$window.smartresize(function() {
                t.isEndPos = false;
                t.getViewportDependentVars();
                t.loop(false);
            });
    },

    // *******************************
    // LOOP
    // *******************************
    stopLoop: function () {
        var t = this;
        cancelAnimationFrame(t.animationloop);
    },

    loop: function (again) {
        var t = this;

        // call detector function
            t.scrollAni();

        // use requestanimationframe for best performance again and again
            if(again) {
               t.animationloop = requestAnimationFrame(jQuery.proxy(function() {
                   t.loop(again);
               },t));
            }
    },

    // *******************************
    // DETECTOR
    // *******************************
    scrollAni: function(force) {
        var t = this, styles;

        t.getScrollposDependentVars();

        if(t.isEndPos && t.aniPos >= 1) {
            return;
        }

        // only do stuff if scrollposition passed startpos and is lower than endpos
        if(t.aniPos >= 0 && t.aniPos <= 1) {
            if(t.aniPos <= 0) {
                styles = calcStyles(t.options.startStyles);
                t.isEndPos = false;
            } else if(t.aniPos >= 1) {
                styles = calcStyles(t.options.endStyles);
                t.isEndPos = true;
            } else {
                styles = t.interpolateStyles();
                t.isEndPos = false;
            }
            t.$elm.css(styles);
        }
    },

    interpolateStyles: function () {
        var t = this, styles = {};

        jQuery.each(t.options.startStyles, function(key, val) {
            styles[key] = t.interpolate(calcStyle(t.options.startStyles[key]), calcStyle(t.options.endStyles[key]), t.aniPos);
        });

        return styles;
    },

    interpolate: function(start,stop,pos) {
        var t = this;
        stop = parseFloat(stop);
        start = parseFloat(start);
        // var easingPos = EasingFunctions.easeOutSine(pos);
        var easingPos = pos;
        var distance = stop - start;
        var progress = easingPos * distance;
        var val = start + progress;
        return val+'px';
    },

    parseOffset: function(val) {
        var t = this, parsedValue;

        if(val === 'windowHeight') {
            parsedValue = -t.windowHeight;
        } else {
            parsedValue = parseFloat(parsedValue);
        }

        return parsedValue;
    }
};

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
EasingFunctions = {
  // no easing, no acceleration
  linear: function (t) { return t; },
  easeInSine: function(t) { return 1-Math.cos(t*Math.PI/2); },
  easeOutSine: function(t) { return Math.sin(t*Math.PI/2); },
  easeInOutSine: function(t) { return -0.5*(Math.cos(Math.PI*t) - 1); },
  // accelerating from zero velocity
  easeInQuad: function (t) { return t*t; },
  // decelerating to zero velocity
  easeOutQuad: function (t) { return t*(2-t); },
  // acceleration until halfway, then deceleration
  easeInOutQuad: function (t) { return t<0.5 ? 2*t*t : -1+(4-2*t)*t; },
  // accelerating from zero velocity 
  easeInCubic: function (t) { return t*t*t; },
  // decelerating to zero velocity 
  easeOutCubic: function (t) { return (--t)*t*t+1; },
  // acceleration until halfway, then deceleration 
  easeInOutCubic: function (t) { return t<0.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1; },
  // accelerating from zero velocity 
  easeInQuart: function (t) { return t*t*t*t; },
  // decelerating to zero velocity 
  easeOutQuart: function (t) { return 1-(--t)*t*t*t; },
  // acceleration until halfway, then deceleration
  easeInOutQuart: function (t) { return t<0.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t; },
  // accelerating from zero velocity
  easeInQuint: function (t) { return t*t*t*t*t; },
  // decelerating to zero velocity
  easeOutQuint: function (t) { return 1+(--t)*t*t*t*t; },
  // acceleration until halfway, then deceleration 
  easeInOutQuint: function (t) { return t<0.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t; }
};
