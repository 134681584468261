// *******************************
// Sticky
// *******************************
var Sticky = function(elm, options) {
    this.init(elm, options);
};
Sticky.prototype = {
    // constructor
    init: function(elm, options) {
        var t = this; t.$elm = jQuery(elm);

        t.$elm.data('Sticky', t);

        t.prepareDataObject(options); // define variables
        t.bindEvents(); // bind events
    },

    // *******************************
    // VARIABLES
    // *******************************
    prepareDataObject: function (options) {
        var t = this;

        // options
        // defaults
        t.options = {
            $elm: t.$elm,                 // element to add sticky class to
            $offsetElm: t.$elm,           // element to check for passing scrollposition
            $pushingElm: null,            // element that pushes the element instead of overlapping
            pushingOffset: 0,             // offset added to the pushing element edge
            offsetTop: null,              // offset to be checked for passing scrollposition
            offsetDelta: 0,               // delta to be added to the passing scrollposition to take into account already sticky elements
            stickyClass: 'sticky',        // class to be added
            preservePosition: false,      // if fixed would break positioning
            viewportOverlapOffset: false, // if fixed would break positioning
            fixedPositioning: false,      // if sticky is using fixed positioning
            viewportEdge: 'top',          // if viewport top is defined as scroll position; alternative is 'bottom'
            elmEdge: 'top',               // if element top edge should be defined as offset; alternative is 'bottom'
            clone: false,                 // if a clone of the element shall be created when going sticky
            emptyClone: false,
            reset: null,
            restore: null
        };

        // extend options with custom params
        jQuery.extend(t.options, options);
        // console.log(t.options);

        t.getViewportDependentVars();

        // elements
        t.$window = jQuery(window);
    },

    getViewportDependentVars: function() {
        var t = this,
            getType = {};

        t.stickyStatus = false;
        t.lock = false;
        t.viewportSize = getViewportSize();

        t.options.$elm.removeClass(t.options.stickyClass);
        t.options.$elm.css({position: '', top: '', left: ''});
        if(t.options.fixedPositioning) {
            t.options.$elm.css({width: ''});
        }
        if(t.options.clone && t.options.$clone) {
            t.options.$clone.hide();
        }

        if(t.options.reset && getType.toString.call(t.options.reset) === '[object Function]') {
            t.options.reset(t);
        }

        t.originalTop = t.options.$elm.offset().top + getViewportOffset().top;
        t.pushingOffset = calcStyle(t.options.pushingOffset);

        // get current (not initial) elm offset
        // if no other scrolltop is defined, take $offsetElms offset
        if(t.options.stickyOffset && getType.toString.call(t.options.stickyOffset) === '[object Function]') {
            t.stickyOffset = t.options.stickyOffset;
        } else {
            if(t.options.offsetTop === null) {
                t.stickyOffset = t.options.$offsetElm.offset().top + getViewportOffset().top;

                if(t.options.elmEdge === 'bottom') {
                    t.stickyOffset = t.stickyOffset + t.options.$offsetElm.outerHeight();
                }

                if(t.options.offsetDelta) {
                    t.stickyOffset -= calcStyle(t.options.offsetDelta);
                }
            } else {
                t.stickyOffset = calcStyle(t.options.offsetTop);
            }

            if(t.options.viewportOverlapOffset) {
                if(t.stickyOffset + t.options.$elm.outerHeight() > t.viewportSize.height) {
                    t.stickyOffset += t.viewportSize.height - t.options.$elm.outerHeight();
                }
            }
        }

        if(t.options.preservePosition) {
            t.options.$elm.css({position: 'absolute', left: ''});
            t.originalLeft = t.options.$elm.offset().left + getViewportOffset().left;
            t.options.$elm.css({position: '', left: ''});
        }

        if(t.options.fixedPositioning) {
            t.fixedLeft = t.options.$elm.offset().left + getViewportOffset().left;
            t.fixedWidth = Math.floor(t.options.$elm.outerWidth());
        }

        if(t.options.restore && getType.toString.call(t.options.restore) === '[object Function]') {
            t.options.restore(t);
        }
    },

    getScrollposDependentVars: function() {
        var t = this;

        // get current (not initial) viewport size and offset
        t.viewportOffset = getViewportOffset().top;
        if(t.options.viewportEdge === 'bottom') {
            t.viewportOffset = t.viewportOffset + t.viewportSize.height;
        }

        t.pushingElmOverlap = 0;
        if(t.options.$pushingElm) {
            var elmBottomEdge = t.originalTop - t.stickyOffset + t.options.$elm.outerHeight();
            var overlap = jQuery(t.options.$pushingElm).offset().top - parseInt(jQuery(t.options.$pushingElm).css('margin-top')) - t.pushingOffset - elmBottomEdge;
            if (overlap < 0) {
                t.pushingElmOverlap = overlap;
            }
        }
    },

    // *******************************
    // EVENTS
    // *******************************
    bindEvents: function () {
        var t = this;

        // FIRST CHECK
        setTimeout(function() {
            t.loop(false);
        }, 100);

        // SCROLLING
        t.$window.on('scrollstart', function() {
            t.loop(true);
        });

        t.$window.on('scrollstop', function() {
            t.stopLoop(false);
        });

        // VIEWPORT RESIZING
        t.$window.smartresize(function() {
            t.getViewportDependentVars();
            t.loop(false);
        });
    },

    // *******************************
    // LOOP
    // *******************************
    stopLoop: function () {
        var t = this;
        cancelAnimationFrame(t.animationloop);
    },

    loop: function (again) {
        var t = this;

        // call detector function
        t.checkSticky();

        // use requestanimationframe for best performance again and again
        if(again) {
            t.animationloop = requestAnimationFrame(jQuery.proxy(function() {
            t.loop(again);
            },t));
        }
    },

    // *******************************
    // DETECTOR
    // *******************************
    checkSticky: function () {
        var t = this, stickyStatus = false;
        if(t.lock) {
            return;
        }
        t.lock = true;

        t.getScrollposDependentVars();

        // detect if scrollposition passed offset
        stickyStatus = t.viewportOffset > calcStyle(t.stickyOffset);

        // console.log('stickyStatus:', stickyStatus, 't.viewportOffset:', t.viewportOffset, 't.stickyOffset:', t.stickyOffset);

        // only do stuff if stickystatus is changed
        if(t.stickyStatus !== stickyStatus) {
            if(stickyStatus) {
                if(t.options.preservePosition || t.options.fixedPositioning) {
                    t.setPos();
                }
                if(t.options.clone) {
                    if(!t.options.$clone) {
                        t.options.$clone = t.options.$elm.clone(false).addClass('sticky-clone').removeAttr('style').insertAfter(t.options.$elm);
                        if(t.options.emptyClone)
                            t.options.$clone.html('').css({width: t.options.$elm.outerWidth(), height: t.options.$elm.outerHeight()});
                    } else {
                        t.options.$clone.show();
                    }
                }
                t.options.$elm.addClass(t.options.stickyClass);
                t.trigger('onStickyAdd');
            } else {
                t.options.$elm.css({position: '', top: '', left: ''});
                if(t.options.clone && t.options.$clone) {
                    t.options.$clone.hide();
                }
                if(t.options.fixedPositioning) {
                    t.options.$elm.css({width: ''});
                }
                t.options.$elm.removeClass(t.options.stickyClass);
                t.trigger('onStickyRemove');
            }
        }

        if(stickyStatus) {
            if(t.options.$pushingElm) {
                t.setPos();
            }
        }

        // keep stickystatus in memory
        t.stickyStatus = stickyStatus;

        t.lock = false;
    },

    setPos: function () {
        var t = this;

        if(t.options.preservePosition) {
            t.options.$elm.css({left: t.originalLeft, top: t.originalTop - calcStyle(t.stickyOffset) + t.pushingElmOverlap});
        } else if(t.options.fixedPositioning) {
            t.options.$elm.css({position: '',left: t.fixedLeft, top: t.originalTop - calcStyle(t.stickyOffset) + t.pushingElmOverlap, width: t.fixedWidth});
        }
    },

    trigger: function(name, data) {
        var t = this;
        if(typeof t.options[name] === 'function') {
            t.options[name].call(t.options.$elm, data);
        }
    }
};
