// *******************************
// Toggler
// *******************************
var Toggler = function(elm, options) {
    this.init(elm, options);
};
Toggler.prototype = {
    // constructor
    init: function(elm, options) {
        var t = this; t.$elm = jQuery(elm);

        t.prepareDataObject(options); // define variables
        t.bindEvents(); // bind events
    },

    // *******************************
    // VARIABLES
    // *******************************
    prepareDataObject: function (options) {
        var t = this;

        // options 
        // defaults
        t.options = {
            $targetElm: t.$elm,
            targetSelector: t.$elm.data('target'),
            targetHasSiblings: t.$elm.data('target-has-siblings'),
            oneway: t.$elm.data('oneway'),
            toggleClass: t.$elm.data('toggle'),
            toggleClassBody: t.$elm.data('body-toggle'),
            ownClass: t.$elm.data('toggle'),
            companionsSelector: false,
            closeSelector: false
        };

        if(t.options.targetSelector) {
            if(t.options.targetSelector === 'parent') {
                t.options.$targetElm = t.$elm.parent();
            } else {
                t.options.$targetElm = t.$elm.closest(t.options.targetSelector);
                if(t.options.$targetElm.length <= 0) {
                    t.options.$targetElm = jQuery(t.options.targetSelector);
                }
            }
        }

        if(!t.options.toggleClass) {
            t.options.toggleClass = 'active';
        }

        // extend options with custom params
        jQuery.extend(t.options, options);
        // console.log(t.options);

        // elements
        t.$window = jQuery(window);
        t.$body = jQuery('body');
    },

    // *******************************
    // EVENTS
    // *******************************
    bindEvents: function () {
        var t = this;

        t.$elm.on('click', function(e) {
            var $elm = jQuery(this);
            e.preventDefault();

            $elm.trigger('blur');

            // if there are siblings of target elm that should be resetted
            if(t.options.targetHasSiblings) {
                if(t.options.targetHasSiblings === true) {
                    t.options.$targetElm.siblings().removeClass(t.options.toggleClass);
                } else {
                    $(t.options.targetHasSiblings).removeClass(t.options.toggleClass);
                }
            }

            if(t.options.companionsSelector && !t.options.$targetElm.hasClass(t.options.toggleClass)) {
                jQuery(t.options.companionsSelector).trigger('click');
            }

            if(!t.options.oneway) {
                 t.options.$targetElm.toggleClass(t.options.toggleClass);
            } else if(t.options.oneway === 'add'){
                 t.options.$targetElm.addClass(t.options.toggleClass);
            } else if(t.options.oneway === 'remove'){
                 t.options.$targetElm.removeClass(t.options.toggleClass);
            }

            if(t.options.$targetElm.hasClass(t.options.toggleClass)) {
                $elm.trigger('df.toggler.add');
                if(t.options.toggleClassBody) {
                     t.$body.addClass(t.options.toggleClassBody);
                 }
                if(t.options.ownClass) {
                    t.$elm.addClass(t.options.ownClass);
                }
            } else {
                $elm.trigger('df.toggler.remove');
                if(t.options.toggleClassBody) {
                     t.$body.removeClass(t.options.toggleClassBody);
                 }
                if(t.options.ownClass) {
                    t.$elm.removeClass(t.options.ownClass);
                }
            }
        });

        if(t.options.closeSelector) {
            t.options.$targetElm.on('click', t.options.closeSelector, function(e) {
                e.preventDefault();
                if(t.options.$targetElm.hasClass(t.options.toggleClass)) {
                    t.$elm.trigger('click');
                } else if(t.options.ownClass && t.$elm.hasClass(t.options.ownClass)) {
                    t.$elm.removeClass(t.options.ownClass);
                }
            });
        }
    }
};
     